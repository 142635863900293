import { AssetType, ChainID, ChainName } from "@omnity/widget/src/types";
import { CHAIN_LIST } from "@omnity/widget/src/utils/constants";
import { EXT_CHAINS } from "./constants";
import { getChainIdFromName } from "@omnity/widget/src/utils/chains";
import { TicketItem } from "src/types";

export function getChainName(chain_id: ChainID) {
  const chain_name = CHAIN_LIST[chain_id]?.chainName as ChainName;
  if (chain_name) {
    return chain_name;
  }
  const extra_chain_name = EXT_CHAINS[chain_id as string];

  return extra_chain_name ?? chain_id;
}

export function extGetChainIdFromName(chain_name: string) {
  const ext_chain_ids = Object.keys(EXT_CHAINS);
  const ext_chain_names = Object.values(EXT_CHAINS);
  const idx = ext_chain_names.indexOf(chain_name);
  if (idx !== -1) {
    return [ext_chain_ids[idx]];
  }
  return getChainIdFromName(chain_name);
}

export function getServiceAssetType(chain_id: ChainID) {
  if (chain_id === ChainID.BitcoinBrc20) {
    return "Brc-20";
  } else if (chain_id === ChainID.Bitcoin) {
    return "Runes";
  } else if (chain_id === ChainID.eICP) {
    return "Runes";
  } else if (chain_id === ChainID.sICP) {
    return "Icrc";
  }
  return "";
}

export function getAssetProtocol(ticket?: Pick<TicketItem, "token">) {
  if (!ticket) {
    return AssetType.runes;
  }
  const [chain, protocol] = ticket.token.split("-");
  if (ticket.token.startsWith("sICP-icrc-ckBTC")) {
    return AssetType.ckbtc;
  } else if (chain === ChainID.sICP || protocol === "icrc") {
    return AssetType.icrc;
  } else if (protocol === "runes") {
    return AssetType.runes;
  } else if (protocol === "brc20") {
    return AssetType.brc20;
  } else if (chain === "dogecoin") {
    return AssetType.doge;
  }
  return AssetType.runes;
}

export function uniqChains(chain_ids: ChainID[]) {
  let chains = chain_ids;
  if (chains.includes(ChainID.eICP) && chains.includes(ChainID.sICP)) {
    chains = [
      ChainID.sICP,
      ...chains.filter((id) => id !== ChainID.sICP && id !== ChainID.eICP),
    ];
  }
  if (
    chains.includes(ChainID.Bitcoin) &&
    chains.includes(ChainID.BitcoinBrc20)
  ) {
    chains = [
      ChainID.Bitcoin,
      ...chains.filter(
        (id) => !(id === ChainID.Bitcoin || id === ChainID.BitcoinBrc20),
      ),
    ];
  }
  return chains;
}

const bitcoinIndexerApi = "https://mempool.space/api";
export async function checkConfirmations(txHash: string): Promise<number> {
  try {
    if (!txHash) {
      throw new Error("Transaction hash is required");
    }
    const tx = await fetch(`${bitcoinIndexerApi}/tx/${txHash}`).then((res) =>
      res.json(),
    );
    let txBlockHeight = tx.status.block_height;
    if (!Number.isInteger(txBlockHeight)) {
      throw new Error("Transaction not found");
    }
    const currentBlockHeight = await fetch(
      `${bitcoinIndexerApi}/blocks/tip/height`,
    ).then((res) => res.json());
    return currentBlockHeight - txBlockHeight + 1;
  } catch (error) {
    return 0;
  }
}
