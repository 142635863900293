import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "./index.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./routes/home.tsx";
import Layout from "./layout.tsx";
import Ticket from "./routes/ticket.tsx";
import Address from "./routes/address.tsx";
import Chains from "./routes/chains.tsx";
import Tokens from "./routes/tokens.tsx";
import { MetadataProvider } from "./contexts/metadata.tsx";
import Token from "./routes/token.tsx";
import Tickets from "./routes/tickets.tsx";
import { ThemeProvider } from "@nivo/core";
import Chain from "./routes/chain.tsx";
import Fee from "./routes/fee.tsx";

// trigger deploy

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "tickets",
        element: <Tickets />,
      },
      {
        path: "ticket/:id",
        element: <Ticket />,
      },
      {
        path: "address/:address",
        element: <Address />,
      },
      {
        path: "token/:token_id",
        element: <Token />,
      },
      {
        path: "chains",
        element: <Chains />,
      },
      {
        path: "fee",
        element: <Fee />,
      },
      {
        path: "chain/:chain_name",
        element: <Chain />,
      },
      {
        path: "Tokens",
        element: <Tokens />,
      },
    ],
  },
]);

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({ config });

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <ThemeProvider>
      <MetadataProvider>
        <ChakraProvider theme={theme}>
          <RouterProvider router={router} />
        </ChakraProvider>
      </MetadataProvider>
    </ThemeProvider>
  </React.StrictMode>,
);
