import { AssetType } from "@omnity/widget/src/types";
import { TicketAction, TicketStatus } from "src/types";

export const PAGE_SIZE = 20;

export const MD_WIDTH = 1200;

export const RICH = "Bitcoin-runes-HOPE•YOU•GET•RICH";

export const EXT_CHAINS: { [key: string]: string } = {
  "osmo-test-5": "Osmosis Testnet",
};

export const TICKET_ACTION_COLORS = {
  [TicketAction.Mint]: "green",
  [TicketAction.Burn]: "red",
  [TicketAction.Transfer]: "blue",
  [TicketAction.Redeem]: "teal",
  [TicketAction.RedeemIcpChainKeyAssets]: "teal",
};

export const TICKET_ACTION_LABEL = {
  [TicketAction.Mint]: TicketAction.Mint,
  [TicketAction.Burn]: TicketAction.Burn,
  [TicketAction.Transfer]: TicketAction.Transfer,
  [TicketAction.Redeem]: TicketAction.Redeem,
  [TicketAction.RedeemIcpChainKeyAssets]: TicketAction.Redeem,
};

export const TICKET_STATUS_TITLES = {
  [TicketStatus.Unknown]: "Unknown",
  [TicketStatus.Pending]: "Pending",
  [TicketStatus.WaitingForConfirmBySrc]: "Sending",
  [TicketStatus.WaitingForConfirmByDest]: "Receiving",
  [TicketStatus.Finalized]: "Finalized",
};

export const ASSET_COLORSCHEMA = {
  [AssetType.brc20]: "green",
  [AssetType.icrc]: "purple",
  [AssetType.ckbtc]: "yellow",
  [AssetType.runes]: "orange",
  [AssetType.doge]: "red",
};
