import {
  HStack,
  Image,
  Stack,
  Link,
  VStack,
  Text,
  Divider,
} from "@chakra-ui/react";
import TELEGRAM from "../assets/telegram.svg";
import TWITTER from "../assets/twitter.svg";
import MEDIUM from "../assets/medium.svg";
import YOUTEBE from "../assets/youtube.svg";
import DISCORD from "../assets/discord.svg";
import GITHUB from "../assets/github.svg";
import EMAIL from "../assets/email.svg";
import OC from "../assets/oc.png";

const Socials = [
  {
    title: "Telegram",
    icon: TELEGRAM,
    href: "https://t.me/omnityofficial",
  },
  {
    title: "Twitter",
    icon: TWITTER,
    href: "https://twitter.com/OmnityNetwork",
  },
  {
    title: "Medium",
    icon: MEDIUM,
    href: "https://medium.com/omnity",
  },
  {
    title: "Youtube",
    icon: YOUTEBE,
    href: "https://www.youtube.com/channel/UCkMYDmXdgjCBTBggSEAy0ZQ",
  },
  {
    title: "Github",
    icon: GITHUB,
    href: "https://github.com/octopus-network",
  },
  {
    title: "OC",
    icon: OC,
    href: "https://oc.app/community/o5uz6-dqaaa-aaaar-bhnia-cai/channel/55564096078728941684293384519740574712/?ref=g6b5s-jqaaa-aaaar-bfbjq-cai",
  },
  {
    title: "Discord",
    icon: DISCORD,
    href: "https://discord.gg/6GTJBkZA9Q",
  },
  {
    title: "Email",
    icon: EMAIL,
    href: "mailto:hi@oct.network",
  },
];

export default function Footer() {
  return (
    <VStack px={{ base: 4, md: 16 }} py={16}>
      <Divider w="100%" h={1} bg="rgba(29, 34, 85, 1)" my={8} />

      <Stack
        w="100%"
        alignItems={{ base: "center", md: "flex-start" }}
        flexDirection={{ base: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Text fontSize={16}>Copyright © 2025 Omnity Network</Text>

        <HStack gap={6}>
          {Socials.map((s) => {
            return (
              <Link
                key={s.title}
                href={s.href}
                target="_blank"
                color="gray.400"
              >
                <Image src={s.icon} alt="social" w={6} h={6} key={s.title} />
              </Link>
            );
          })}
        </HStack>
      </Stack>
    </VStack>
  );
}
