import {
  Box,
  Circle,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  VStack,
  Divider,
  Spinner,
  AvatarGroup,
  Avatar,
} from "@chakra-ui/react";
import ServiceFactory from "@omnity/widget/src/services/Factory";
import { ChainID, ChainType } from "@omnity/widget/src/types";
import { CHAIN_LIST } from "@omnity/widget/src/utils/constants";
import { formatUnits, readableNumber } from "@omnity/widget/src/utils/format";
import dayjs from "dayjs";
import { gql } from "graphql-request";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import AssetBadge from "src/components/AssetBadge";
import IChainLogo from "src/components/IChainLogo";
import { useMetadata } from "src/contexts/metadata";
import useVolume from "src/hooks/useVolume";
import { getChainName } from "src/utils/chains";
import { MD_WIDTH } from "src/utils/constants";
import { fetchGraph } from "src/utils/graphFetch";

interface FeeLog {
  chain_id: ChainID;
  amount: string;
  date: string;
}

async function getFeeLogs(): Promise<FeeLog[]> {
  try {
    const now = Date.now();
    const d7 = now - 1000 * 60 * 60 * 24 * 10;
    // const up = dayjs(now).format("YYYY-MM-DD");
    const down = dayjs(d7).format("YYYY-MM-DD");
    const doc = gql`
      {
        bridge_fee_log(
          order_by: { date: desc }
          where: { date: { _gte: "${down}" } }
        ) {
          chain_id
          amount
          date
        }
      }
    `;
    const data = await fetchGraph(doc, {});
    return data.bridge_fee_log;
  } catch (error) {
    return [];
  }
}

function getFeeInTotal(
  chainId: ChainID,
  logs: FeeLog[],
  d: "1D" | "3D" | "7D",
): string {
  if (logs.length === 0) {
    return "-";
  }
  const chainLogs = logs.filter((l) => l.chain_id === chainId);
  if (!logs[0]) {
    return "-";
  }
  const lastUpdateDate = new Date(logs[0].date);
  let formerDate = new Date();
  if (d === "1D") {
    formerDate = new Date(lastUpdateDate.getTime() - 1000 * 60 * 60 * 24);
  } else if (d === "3D") {
    formerDate = new Date(lastUpdateDate.getTime() - 1000 * 60 * 60 * 24 * 3);
  } else if (d === "7D") {
    formerDate = new Date(lastUpdateDate.getTime() - 1000 * 60 * 60 * 24 * 7);
  }

  const rangeL = dayjs(formerDate).format("YYYY-MM-DD");
  const rangeR = dayjs(lastUpdateDate).format("YYYY-MM-DD");
  const chainDateLogs = chainLogs.filter(
    (l) => l.date > rangeL && l.date <= rangeR,
  );

  const total = chainDateLogs.reduce((acc, cur) => {
    return acc + BigInt(cur.amount);
  }, 0n);
  const feeToken = ServiceFactory.getFeeToken(chainId);
  const amount = formatUnits(total, feeToken?.decimals);
  return amount;
}

export default function Fee() {
  const [logs, setLogs] = useState<FeeLog[]>([]);
  const { chains } = useMetadata();
  const navigate = useNavigate();
  const { chainVolumes } = useVolume();
  const [prices, setPrices] = useState();

  useEffect(() => {
    getFeeLogs().then((res) => {
      setLogs(res);
    });

    fetch(`https://api.omnity.network/api/price/bridge`)
      .then((res) => res.json())
      .then((res) => {
        setPrices(res.prices);
      })
      .catch(console.error);
  }, []);

  return (
    <VStack w="100%" alignItems="flex-start" mt={8} gap={0}>
      <Helmet>
        <title>Fee Analysis | Omnity Hub Explorer</title>
      </Helmet>
      <VStack w="100%" gap={1}>
        <Text fontSize={28}>Fee Analysis</Text>
        <Text color="gray.400">
          Last updated at {logs.length > 0 ? logs[0]?.date : "-"}
        </Text>
      </VStack>

      <Divider bg="gray.500" w="100%" h={1} mt={4} />

      <Box w={{ base: window.innerWidth, md: "100%" }} overflowX="scroll">
        <TableContainer w={{ base: "100%", md: MD_WIDTH }}>
          <Table size="lg">
            <Thead>
              <Tr>
                <Th>Chain ID</Th>
                <Th textAlign="right">TVL</Th>
                <Th textAlign="right">1D</Th>
                <Th textAlign="right">3D</Th>
                <Th textAlign="right">7D</Th>
                <Th textAlign="right">Token</Th>
              </Tr>
            </Thead>
            <Tbody>
              {chains
                .map((chain) => {
                  const volume =
                    chainVolumes?.find((c) => c.chain_id === chain.chain_id)
                      ?.volume ?? 0;
                  return {
                    ...chain,
                    volume,
                  };
                })
                .sort((a, b) => b.volume - a.volume)
                .map((chain) => {
                  const chain_id = chain.chain_id as ChainID;
                  const chain_name = getChainName(chain_id);
                  if (chain.chain_type === ChainType.Settlement) {
                    return null;
                  }
                  const feeToken = ServiceFactory.getFeeToken(chain_id);
                  const isBitcoin = chain_id === ChainID.Bitcoin;
                  const d1fee = getFeeInTotal(chain_id, logs, "1D");
                  const d3fee = getFeeInTotal(chain_id, logs, "3D");
                  const d7fee = getFeeInTotal(chain_id, logs, "7D");
                  return (
                    <Tr
                      key={chain_id}
                      cursor="pointer"
                      _hover={{ bg: "gray.700" }}
                      onClick={() => navigate(`/chain/${chain_name}`)}
                    >
                      <Td>
                        <HStack>
                          <IChainLogo chain={chain_id} size={32} />
                          <Text fontWeight={600} fontSize={20}>
                            {chain_id}
                          </Text>
                        </HStack>
                      </Td>
                      <Td
                        textAlign="right"
                        fontWeight={600}
                        fontSize={18}
                        className="number"
                      >
                        {chain.volume && !isBitcoin
                          ? `$${readableNumber(Math.ceil(chain.volume), 0)}`
                          : "-"}
                      </Td>
                      <Th textAlign="right">
                        <VStack gap={0} alignItems="flex-end">
                          <Text>{d1fee}</Text>
                          <Text fontWeight={400} fontSize={12} color="gray.500">
                            {!(d1fee === "0" || d1fee === "-") && prices
                              ? `$${readableNumber(
                                  Number(d1fee) *
                                    Number(
                                      (prices as any)[feeToken?.symbol] ?? 0,
                                    ),
                                )}`
                              : ""}
                          </Text>
                        </VStack>
                      </Th>
                      <Th textAlign="right">
                        <VStack gap={0} alignItems="flex-end">
                          <Text>{d3fee}</Text>
                          <Text fontWeight={400} fontSize={12} color="gray.500">
                            {!(d3fee === "0" || d3fee === "-") && prices
                              ? `$${readableNumber(
                                  Number(d3fee) *
                                    Number(
                                      (prices as any)[feeToken?.symbol] ?? 0,
                                    ),
                                )}`
                              : ""}
                          </Text>
                        </VStack>
                      </Th>
                      <Th textAlign="right">
                        <VStack gap={0} alignItems="flex-end">
                          <Text>{d7fee}</Text>
                          <Text fontWeight={400} fontSize={12} color="gray.500">
                            {!(d7fee === "0" || d7fee === "-") && prices
                              ? `$${readableNumber(
                                  Number(d7fee) *
                                    Number(
                                      (prices as any)[feeToken?.symbol] ?? 0,
                                    ),
                                )}`
                              : ""}
                          </Text>
                        </VStack>
                      </Th>
                      <Th textAlign="right">{feeToken?.symbol}</Th>
                    </Tr>
                  );
                })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </VStack>
  );
}
