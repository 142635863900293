import {
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  HStack,
  Image,
  Input,
  Text,
  useDisclosure,
  VStack,
  chakra,
  Button,
} from "@chakra-ui/react";
import LOGO from "../assets/logo.svg";
import { useRef, useState } from "react";
import { getKeywordType } from "src/utils/format";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ArrowUpRight, Menu } from "lucide-react";

const HamburgerIcon = chakra(Menu);

const Menus = [
  {
    title: "Home",
    href: "/",
  },
  {
    title: "Tickets",
    href: "/tickets",
  },
  {
    title: "Chains",
    href: "/chains",
  },
  {
    title: "Tokens",
    href: "/tokens",
  },
  {
    title: "Bridge",
    href: "https://bridge.omnity.network",
  },
];

export default function Header() {
  const [keyword, setKeyword] = useState("");
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  const location = useLocation();
  const isHome = location.pathname === "/";
  return (
    <HStack
      zIndex={100}
      px={{ base: 4, md: 16 }}
      py={{ base: 2, md: 8 }}
      justifyContent="space-between"
    >
      <HStack gap={20}>
        <Link to="/">
          <HStack alignItems="center" gap={2}>
            <Image src={LOGO} alt="logo" />
            <Text
              fontWeight={600}
              fontSize={24}
              color="white"
              _hover={{ textDecoration: "none" }}
              display={{ base: "none", md: "inline-block" }}
            >
              Omnity Hub Explorer
            </Text>
          </HStack>
        </Link>

        <HStack
          gap={6}
          fontSize={12}
          pos="relative"
          top={1}
          display={{ base: "none", md: "flex" }}
        >
          {Menus.map((m) => {
            const isActive = location.pathname === m.href;
            if (isHome && m.title === "Bridge") {
              return null;
            }
            return (
              <Link
                to={m.href}
                key={m.title}
                target={m.href.startsWith("http") ? "_blank" : ""}
              >
                <HStack
                  gap={1}
                  color={isActive ? "gray.100" : "gray.500"}
                  fontSize={16}
                  _hover={{ color: "gray.100" }}
                >
                  <Text className="number">{m.title.toUpperCase()}</Text>
                  {m.href.startsWith("http") && <ArrowUpRight size={16} />}
                </HStack>
              </Link>
            );
          })}
        </HStack>
      </HStack>

      {!isHome ? (
        <Input
          placeholder="Search Ticket/Address"
          w={300}
          borderColor="gray.400"
          borderRadius="full"
          value={keyword}
          display={{ base: "none", md: "block" }}
          onChange={(e) => setKeyword(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const type = getKeywordType(keyword.trim());
              if (type === "address") {
                navigate(`/address/${encodeURIComponent(keyword.trim())}`);
              } else {
                navigate(`/ticket/${encodeURIComponent(keyword.trim())}`);
              }
              setKeyword("");
            }
          }}
        />
      ) : (
        <Button
          background="linear-gradient(90deg, #5A85E9 -16.11%, #B120BD 105.23%)"
          borderRadius="full"
          size="sm"
          as={Link}
          to="https://bridge.omnity.network"
          target="_blank"
          px={4}
          color="white"
          _hover={{
            bg: "linear-gradient(90deg, #5A85E9 -16.11%, #B120BD 105.23%)",
            color: "white",
          }}
          rightIcon={<ArrowUpRight size={16} />}
        >
          Bridge
        </Button>
      )}

      <IconButton
        display={{ base: "", md: "none" }}
        icon={<HamburgerIcon color="white" size={25} />}
        aria-label={""}
        bg="transparent"
        borderWidth={0}
        _active={{ bg: "transparent" }}
        _hover={{ bg: "transparent" }}
        onClick={onOpen}
      />
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={onClose}
        finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent bg="#000433" color="white">
          <DrawerCloseButton color="white" />
          <DrawerBody>
            <VStack gap={4} pt={24} alignItems="flex-end">
              {Menus.map((m) => {
                return (
                  <Link to={m.href} key={m.title} onClick={onClose}>
                    <HStack gap={1} fontSize={24} color="white">
                      <Text>{m.title.toUpperCase()}</Text>
                      {m.href.startsWith("http") && <ArrowUpRight size={16} />}
                    </HStack>
                  </Link>
                );
              })}
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </HStack>
  );
}
