import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  TableCaption,
  HStack,
  Text,
  Avatar,
  Badge,
  Box,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { TicketAction, TicketItem } from "../types";
import TicketStatusComp from "./TicketStatusComp";
import { ArrowRight } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { ChainID } from "@omnity/widget/src/types";
import { useMetadata } from "src/contexts/metadata";
import relativeTime from "dayjs/plugin/relativeTime";
import {
  MD_WIDTH,
  TICKET_ACTION_COLORS,
  TICKET_ACTION_LABEL,
} from "src/utils/constants";
import { beautifyAddress, beautifyTokenId } from "src/utils/format";
import IChainLogo from "./IChainLogo";
import { getChainName, getAssetProtocol } from "src/utils/chains";
import AssetBadge from "./AssetBadge";

dayjs.extend(relativeTime);

export default function TicketTable({ items }: { items: TicketItem[] }) {
  const navigate = useNavigate();
  const { formatTokenAmount } = useMetadata();

  return (
    <Box w={{ base: window.innerWidth, md: "100%" }} overflowX="scroll">
      <TableContainer minW={{ base: "100%", md: MD_WIDTH }} w="100%">
        <Table size="lg">
          <TableCaption>
            {items.length === 0 ? "There is no tickets" : ""}
          </TableCaption>
          <Thead>
            <Tr>
              <Th pl={0}>Status</Th>
              <Th px={0}>Chains</Th>
              <Th pr={0}>Asset Type</Th>
              <Th pr={0}>Token</Th>
              <Th>From</Th>
              <Th px={0}>To</Th>
              <Th>Created</Th>
            </Tr>
          </Thead>
          <Tbody>
            {items.map((item) => {
              const token = formatTokenAmount?.(item.amount, item.token, false);
              const sender = beautifyAddress(item.sender);
              const receiver = beautifyAddress(item.receiver);
              const assetType = getAssetProtocol(item);
              return (
                <Tr
                  key={item.ticket_id}
                  cursor="pointer"
                  _hover={{ bg: "gray.700" }}
                  onClick={() => navigate(`/ticket/${item.ticket_id}`)}
                >
                  <Td pl={0}>
                    <TicketStatusComp status={item.status} />
                  </Td>
                  <Td px={0}>
                    <HStack>
                      <Link
                        to={
                          item.src_chain
                            ? `/chain/${getChainName(item.src_chain as ChainID)}`
                            : "#"
                        }
                        onClick={(e) => e.stopPropagation()}
                      >
                        <IChainLogo
                          chain={item.src_chain as ChainID}
                          size={24}
                        />
                      </Link>
                      {[
                        TicketAction.Transfer,
                        TicketAction.Redeem,
                        TicketAction.RedeemIcpChainKeyAssets,
                      ].includes(item.action) && (
                        <>
                          <ArrowRight color="white" size={18} strokeWidth={1} />
                          <Link
                            to={
                              item.dst_chain
                                ? `/chain/${getChainName(item.dst_chain as ChainID)}`
                                : "#"
                            }
                            onClick={(e) => e.stopPropagation()}
                          >
                            <IChainLogo
                              chain={item.dst_chain as ChainID}
                              size={24}
                            />
                          </Link>
                        </>
                      )}
                    </HStack>
                  </Td>
                  <Td pr={0}>
                    <AssetBadge assetType={assetType} />
                  </Td>
                  <Td pr={0}>
                    <HStack>
                      <Badge colorScheme={TICKET_ACTION_COLORS[item.action]}>
                        {TICKET_ACTION_LABEL[item.action]}
                      </Badge>
                      <Text className="number">{token?.balance}</Text>
                      <Link
                        to={
                          item.token
                            ? `/token/${beautifyTokenId(item.token)}`
                            : "#"
                        }
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Avatar
                          size="xs"
                          src={token?.icon}
                          name={token?.symbol}
                          borderWidth={1}
                          borderColor="gray.500"
                        />
                      </Link>
                    </HStack>
                  </Td>
                  <Td>
                    <Link
                      to={sender ? `/address/${sender}` : "#"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Text
                        fontFamily="monospace"
                        color="blue.400"
                        fontSize={16}
                      >
                        {sender ? sender.slice(0, 10) + "..." : "-"}
                      </Text>
                    </Link>
                  </Td>
                  <Td px={0}>
                    <Link
                      to={receiver ? `/address/${receiver}` : "#"}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Text
                        fontFamily="monospace"
                        color="blue.400"
                        fontSize={16}
                      >
                        {receiver ? receiver.slice(0, 10) + "..." : "-"}
                      </Text>
                    </Link>
                  </Td>
                  <Td color="gray.500" className="number">
                    {formatTicketTime(item.ticket_time)}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
}

function formatTicketTime(time: number) {
  const _time = Math.floor(time / 1000000);
  if (_time < 0) {
    return "-";
  }
  const _now = Date.now();
  if (_now - _time > 1000 * 60 * 60 * 24 * 15) {
    return dayjs(_time).format("YYYY-MM-DD HH:mm:ss");
  }
  return dayjs(_time).fromNow();
}
