import { useEffect, useMemo } from "react";
import { useMetadata } from "src/contexts/metadata";
import _ from "lodash";
import { atom, useAtom } from "jotai";

const totalVolumeAtom = atom<number>(0);

export default function useVolume() {
  const [totalVolume, setTotalVolume] = useAtom(totalVolumeAtom);
  const { chains, tokens, priceFetched, formatTokenAmount } = useMetadata();

  const chainVolumes = useMemo(() => {
    if (priceFetched) {
      return chains.map((chain) => {
        const volume = tokens.reduce((acc, t) => {
          const l2_amount = t.amount_on_l2?.find(
            (t) => t.chain_id === chain.chain_id,
          );
          if (l2_amount) {
            acc +=
              Number(
                formatTokenAmount?.(l2_amount.amount, t.token_id, false)
                  .balance,
              ) * Number(t.price === undefined ? 0 : t.price);
          }
          return acc;
        }, 0);
        return {
          chain_id: chain.chain_id,
          volume,
        };
      });
    }
    return [];
  }, [chains.length, tokens.length, priceFetched]);

  useEffect(() => {
    if (priceFetched) {
      const total = _.sumBy(chainVolumes, (v) => v.volume ?? 0);
      setTotalVolume(total);
    }
  }, [chainVolumes.length, priceFetched]);

  const tokenVolumes = useMemo(() => {
    return tokens.map((t) => {
      const _volumeAmount = formatTokenAmount?.(
        t.volumeAmount?.toString() ?? "0",
        t.token_id,
        false,
      );

      const balance = _volumeAmount?.balance ?? 0;
      const hasPrice = t.price !== "0";
      if (hasPrice) {
        return {
          token_id: t.token_id,
          volume: Number(balance) * Number(t.price),
        };
      } else {
        return {
          token_id: t.token_id,
          volume: 0,
        };
      }
    });
  }, [tokens.length, priceFetched]);

  return {
    totalVolume,
    chainVolumes: chainVolumes,
    tokenVolumes,
  };
}
